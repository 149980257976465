<style scoped></style>
<template>
  <div>
    <b-modal hide-footer ref="payments_modal" size="lg" title="Payment list">
      <div class="row">
        <div class="col">
          <b-card
            bg-variant="dark"
            text-variant="white"
            header="Amount"
            class="text-center"
          >
            <b-card-text>{{ form.item.amount }}</b-card-text>
          </b-card>
        </div>
        <div class="col">
          <b-card
            bg-variant="success"
            text-variant="white"
            header="Paid"
            class="text-center"
          >
            <b-card-text>{{ form.item.paid }}</b-card-text>
          </b-card>
        </div>
        <div class="col">
          <b-card
            bg-variant="warning"
            text-variant="white"
            header="Balance"
            class="text-center"
          >
            <b-card-text>{{ form.item.balance }}</b-card-text>
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="row mt-2">
            <div class="col-4">
              <label for="paying_amount">Now paying amount</label>
              <input
                min="0"
                type="number"
                v-model="form.paying_amount"
                name="paying_amount"
                id="paying_amount"
                class="form-control"
                @focus="$event.target.select()"
              />
              <span class="text-danger" v-if="error != ''">{{ error }}</span>
            </div>
            <div class="col-3 mt-3 pt-2">
              <b-button variant="primary" @click="addPayment()" :disabled="loading"
                ><b-icon icon="currency-dollar" aria-hidden="true"></b-icon> Add</b-button
              >
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-table
                small
                striped
                hover
                :fields="fields"
                :items="items"
                responsive="sm"
                show-empty
                :empty-text="'There are no related payments to show'"
                class="mt-4"
              >
                <template #empty="scope">
                  <p class="text-center mt-3">{{ scope.emptyText }}</p>
                </template>
                <template #emptyfiltered="scope">
                  <p class="text-center mt-3">{{ scope.emptyFilteredText }}</p>
                </template>

                <template #cell(amount)="data">
                  {{ data.item.amount }}
                </template>

                <template #cell(date_time)="data">
                  {{ data.item.date_time }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell(id)="data">
                  <b-button
                    size="sm"
                    class="float-end"
                    variant="danger"
                    v-if="$parent.form.role == 1"
                    @click="removePayment(data.item)"
                    ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                  ></b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      loading: false,
      form: {
        invoice_id: null,
        invoice_item_id: null,
        paying_amount: 0,
        item: [],
      },
      fields: [
        { key: "amount", label: "Amount" },
        { key: "date_time", label: "Date time" },
        { key: "id", label: "" },
      ],
      items: [],
      error: ''
    };
  },
  computed: {},
  methods: {
    openList(invoice_id, invoice_item_id) {
      this.form.invoice_id = invoice_id;
      this.form.invoice_item_id = invoice_item_id; 

      this.$refs["payments_modal"].show();
      this.getPayments();
    },
    getPayments() {
      axios.post("/j/get-payments", this.form).then((response) => {
        if (response.data.data) {
          this.items = response.data.data;
          this.form.item = response.data.item;
        }
      });
    },
    removePayment(item) {
      axios.post("/j/remove-payment", item).then((response) => {
        if (response.data.status) {
          this.getPayments();
          this.$parent.refresh();

        }
      });
    },
    addPayment() {
      if(this.error == '' && parseInt(this.form.paying_amount) > 0){
        this.loading = true;
        axios.post("/j/add-payment", this.form).then((response) => {
        if (response.data.status) {
          this.form.paying_amount = 0;
          this.getPayments();
          this.$parent.refresh();
        }
          this.loading = false;
      });
      } 
    },
  },
  watch: {
    "form.paying_amount": function(){
        if(parseInt(this.form.paying_amount) > this.form.item.balance){
          this.error = 'Amount should be equal or less than balance amount';
        }else{
          this.error = '';
        }
    }
  },
};
</script>
