<template>
  <div class="row justify-content-center">
    <div class="col-md-12">
      <div class="card">
        <div class="card-header">
          {{ form.id ? "Invoice - " + form.invoice_unique_label : "Add new invoice" }}
        </div>
        <div class="card-body">
          <div class="container bg-light p-3 mb-2 p-2">
            <div class="row">
              <div class="col d-flex">
                <div>
                  <label for="invoice_number">Invoice No</label>
                  <input
                    class="form-control"
                    v-model="form.invoice_unique_label"
                    v-if="form.manual_invoice_no"
                  />
                  <input
                    v-else
                    disabled
                    class="form-control"
                    v-model="form.invoice_unique_label"
                  />
                </div>
                <div>
                  <label
                    class="pt-4 mt-1 ps-2 text-primary fw-bold text-uppercase"
                    v-if="form.invoice.id == null"
                    @click="toggleInvoiceNo()"
                    >{{ form.manual_invoice_no ? "Auto" : "Manual" }}</label
                  >
                </div>
              </div>
              <div class="col">
                <label for="Invoice_date">Date</label>
                <input
                  v-if="form.invoice.id == null"
                  type="date"
                  v-model="form.date"
                  class="form-control"
                />
                <input
                  v-else
                  disabled
                  type="date"
                  v-model="form.date"
                  class="form-control"
                />
              </div>
              <div class="col">
                <div>
                  <label for="order_status">Order Status</label>
                  <select
                    v-model="form.order_status"
                    class="form-select"
                    aria-label="order_status"
                  >
                    <option value="Not Issued">Not Issued</option>
                    <option value="Issued">Issued</option>
                  </select>
                </div>
              </div>
              <div class="col bg-dark pb-2 pe-0 pe-2">
                <div class="row">
                  <div class="col">
                    <label for="search_invoice text-light" style="color: white"
                      >Search Invoice No</label
                    >
                    <multiselect
                      v-model="form.invoice"
                      placeholder="Search Invoice"
                      label="invoice_unique_label"
                      track-by="invoice_unique"
                      :options="invoices"
                      :multiple="false"
                      :taggable="false"
                      @search-change="getInvoices"
                      @input="changeInvoice"
                      :disabled="form.invoice.id != null"
                    ></multiselect>
                  </div>
                  <div class="col-4" v-if="form.invoice.id != null">
                    <b-button class="mt-4" @click="clearInvoice()">Clear</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container bg-light p-3 p-2 mb-2">
            <div class="row">
              <div class="col-4">
                <label for="customer">Customer</label>
                <multiselect
                  v-model="form.customer"
                  tag-placeholder="Insert name"
                  placeholder="Search name"
                  label="name"
                  track-by="id"
                  :options="customers"
                  :multiple="false"
                  :taggable="true"
                  @tag="addCustomer"
                  @search-change="getCustomers"
                ></multiselect>
              </div>
              <div class="col">
                <label for="remarks">Remarks</label>
                <input type="text" v-model="form.remarks" class="form-control" />
              </div>
            </div>
          </div>

          <div class="container bg-light p-3 p-2 mb-2">
            <div class="row">
              <div class="col d-block">
                <p class="mb-0">Select Job</p>
                <button @click="toggleJob()" class="btn btn-dark w-100">
                  {{
                    invoice_item_row.job_unique
                      ? invoice_item_row.job_unique + " (Clear)"
                      : "Find"
                  }}
                </button>
              </div>
              <div class="col-2">
                <label for="qty">Quantity</label>
                <input
                  min="0"
                  type="number"
                  v-model="invoice_item_row.qty"
                  name="qty"
                  id="qty"
                  class="form-control"
                  @focus="$event.target.select()"
                  :disabled="disable_item_inser_row"
                />
              </div>
              <div class="col-3">
                <label for="descript">Description</label>
                <input
                  type="text"
                  v-model="invoice_item_row.description"
                  name="descript"
                  id="descript"
                  class="form-control"
                  :disabled="disable_item_inser_row"
                />
              </div>
              <div class="col">
                <label for="amount">Amount</label>
                <input
                  min="0"
                  type="number"
                  name="amount"
                  id="amount"
                  class="form-control fw-bold text-end"
                  v-model="invoice_item_row.amount"
                  @focus="$event.target.select()"
                  @keyup="updateAmounts"
                  :disabled="disable_item_inser_row"
                />
              </div>
              <div class="col">
                <label for="paid_amount">Paid Amount</label>
                <input
                  min="0"
                  type="number"
                  name="paid_amount"
                  id="paid_amount"
                  class="form-control fw-bold text-end"
                  v-model="invoice_item_row.paid"
                  @focus="$event.target.select()"
                  @keyup="updateAmounts"
                  :disabled="disable_item_inser_row"
                />
              </div>
              <div class="col">
                <label for="balance">Balance</label>
                <input
                  readonly
                  type="number"
                  name="balance"
                  id="balance"
                  class="form-control text-danger fw-bold text-end"
                  v-model="invoice_item_row.balance"
                  :disabled="disable_item_inser_row"
                />
              </div>
              <div class="col-1 mt-3 pt-1">
                <button class="btn btn-dark w-100" @click="addInvoiceRow()">Add</button>
              </div>
            </div>
          </div>

          <div class="container bg-light p-3 p-2">
            <table class="small table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col">Job No</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Description</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Paid Amount</th>
                  <th scope="col">Balance</th>
                  <th scope="col" v-if="form.role == 1 || (form.role == 0 && form.invoice.id == null)"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(iitem, index) in form.invoice_item" :key="iitem">
                  <td>
                    <a :href="'/job/' + iitem['job_unique']"
                      ><span class="badge bg-warning">{{ iitem["job_unique"] }}</span></a
                    >
                  </td>
                  <td>{{ iitem["qty"] }}</td>
                  <td>{{ iitem["description"] }}</td>
                  <td>{{ iitem["amount"] }}</td>
                  <td>{{ iitem["paid"] }}</td>
                  <td>{{ iitem["balance"] }}</td>
                  <td v-if="form.role == 1 || (form.role == 0 && form.invoice.id == null)">
                    <b-button
                      variant="danger"
                      size="sm"
                      @click="deleteInvoiceItem(iitem, index)"
                      ><b-icon icon="trash-fill" aria-hidden="true"></b-icon
                    ></b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="container p-2 text-align-right">
            <div class="row">
              <div class="col bg-light p-3">
                <div class="row">
                  <div class="col text-right">
                    <b-button
                      variant="dark"
                      size="sm"
                      @click="viewInvoicePayment()"
                      v-if="form.invoice.id != null && form.balance > 0"
                      ><b-icon icon="currency-dollar" aria-hidden="true"></b-icon>
                      Payments
                    </b-button>
                    <b-button
                      variant="dark"
                      size="sm"
                      @click="viewInvoicePayment()"
                      v-else-if="form.invoice.id != null"
                      ><b-icon icon="currency-dollar" aria-hidden="true"></b-icon> View
                      Payments
                    </b-button>
                    <b-button
                      variant="warning"
                      size="sm"
                      @click="payAll()"
                      v-if="form.invoice.id != null && form.balance > 0"
                      ><b-icon icon="credit-card" aria-hidden="true"></b-icon> Pay
                      all</b-button
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <label for="pay">Payment Details</label>
                    <textarea
                      class="form-control"
                      v-model="form.payment_details"
                      id="pay"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>

              <div class="col pt-4 ms-1 me-1 bg-light">
                <div class="row justify-content-between">
                  <div class="col-6">
                    <label for="total">Total</label>
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      name="total"
                      class="form-control fw-bold text-end m-1"
                      v-model="form.total"
                      readonly
                    />
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="col-6">
                    <label for="paid">Paid Amount</label>
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      name="paid"
                      id="paid"
                      class="form-control text-success fw-bold text-end m-1"
                      v-model="form.paid"
                      readonly
                    />
                  </div>
                </div>
                <div class="row justify-content-between">
                  <div class="col-6">
                    <label for="pay_balance" class="fw-bold">Balance</label>
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      name="pay_balance"
                      id="pay_balance"
                      class="form-control text-danger fw-bold text-end m-1"
                      v-model="form.balance"
                      readonly
                    />
                  </div>
                </div>
              </div>
              <div class="col bg-dark p-3">
                <div class="d-flex justify-content-between pt-2">
                  <button class="btn btn-light w-100" @click="clearInvoice()">
                    Cancel
                  </button>
                  <button @click="deleteInvoice()" class="btn btn-danger w-100 ms-1 me-1" v-if="form.role == 1 && form.invoice.id != null">Delete</button>
                  <button
                    class="btn btn-primary w-100 ms-1"
                    @click="save"
                    :disabled="loading"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <job-list-modal ref="job_list_component"></job-list-modal>

    <payment-list-modal ref="payment_list_component"></payment-list-modal>

    <notifications group="error" position="bottom right" />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import moment from "moment";
import JobList from "./modals/JobList.vue";
import PaymentList from "./modals/PaymentList.vue";

import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
    "job-list-modal": JobList,
    "payment-list-modal": PaymentList,
  },
  props: ["invoice_details"],
  data() {
    return {
      loading: false, 
      form: {
        role: 0,
        invoice: {
          id: null,
          invoice_unique: null,
          invoice_unique_label: null,
        },
        invoice_unique_label: "XXXXX",
        manual_invoice_no: false,
        date: moment(new Date()).format("YYYY-MM-DD"),
        customer: {
          id: null,
          name: null,
        },
        order_status: "Not Issued",
        remarks: null,
        payment_details: null,
        total: 0,
        paid: 0,
        balance: 0,
        invoice_item: [],
      },
      customers: [],
      invoices: [],
      invoice_item_row: {
        id: null,
        job_unique: null,
        qty: 0,
        description: null,
        amount: 0,
        paid: 0,
        balance: 0,
        status: "store",
      },
      disable_item_inser_row: false,
    };
  },
  computed: {},
  created() {
    this.getCustomers();
    this.getInvoices();
    console.log(this.invoice_details);
    if (typeof this.invoice_details !== "undefined") {
      this.form = this.invoice_details;
    }
  },
  methods: {
    deleteInvoice(){ 
      this.$bvModal
        .msgBoxConfirm("Are you sure want to delete this invoice?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios.post("/j/delete-invoice", { id: this.form.invoice.id }).then((response) => {
              if (response.data.status) { 
                  document.location = '/invoices';
              }
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });

       
    },
    toggleJob() {
      if (this.invoice_item_row.job_unique != null) {
        this.invoice_item_row.job_unique = null;
        this.disable_item_inser_row = false;
        this.invoice_item_row = {
          id: null,
          job_unique: null,
          qty: 0,
          description: null,
          amount: 0,
          paid: 0,
          balance: 0,
          status: "store",
        };
      } else {
       
        this.$refs["job_list_component"].openList(
          this.form.invoice_item,
          this.form.customer == null ? null : this.form.customer.id
        );
      }
    },
    addPayment() {},
    payAll() {
      this.$bvModal
        .msgBoxConfirm("Are you sure want to complete payment of this Invoice?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios.post("/j/pay-all", { id: this.form.invoice.id }).then((response) => {
              if (response.data.status) {
                this.refresh();
              }
            });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    viewInvoicePayment() {
      this.$refs["payment_list_component"].openList(this.form.invoice.id, null);
    },
    deleteInvoiceItem(item, index) {
      this.$bvModal
        .msgBoxConfirm("Are you sure want to remove this item?", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.form.total = parseInt(this.form.total) - parseInt(item.amount);
            this.form.paid = parseInt(this.form.paid) - parseInt(item.paid);
            this.form.balance = parseInt(this.form.balance) - parseInt(item.balance);
            if (this.form.invoice.id != null) {
              item.status = "delete";
              this.save();
            } else {
              this.$delete(this.form.invoice_item, index);
            }
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    toggleInvoiceNo() {
      this.form.manual_invoice_no = !this.form.manual_invoice_no;
      if (this.form.manual_invoice_no) {
        this.form.invoice_unique_label = "";
      } else {
        this.form.invoice_unique_label = "XXXXX";
      }
    },
    save() {
      this.loading = true;
      axios.post("/j/save/invoice", this.form).then((response) => {
        if (response.data.errors) {
          this.$notify({
            group: "error",
            type: "error",
            title: "Error",
            text: response.data.text,
          });
        } else {
          this.form = response.data.data;
          history.pushState(null, null, "/invoice/" + this.form.invoice.invoice_unique);
        }
        this.loading = false;
      });
    },
    updateAmounts() {
      this.invoice_item_row.balance =
        this.invoice_item_row.amount - this.invoice_item_row.paid;
    },
    doJobAssingToRow(job) {
      this.invoice_item_row.job_unique = job.job_unique;
      this.invoice_item_row.qty = job.qty;
      this.invoice_item_row.description = job.description;
      this.invoice_item_row.amount = job.amount;
      this.invoice_item_row.balance = job.balance;
      this.invoice_item_row.paid = job.paid;
      this.invoice_item_row.status = "store";
      this.disable_item_inser_row = true;
    },
    addJobItemRow(job) {
      if (this.form.customer.id == job.customer_id) {
        this.doJobAssingToRow(job);
      } else {
        this.$bvModal
          .msgBoxConfirm(
            'Do you want to import customer "' +
              job.customer +
              '" from ' +
              job.job_unique +
              " job?",
            {
              title: "Please Confirm",
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "YES",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.form.customer.name = job.customer;
              this.form.customer.id = job.customer_id;
            }
            this.doJobAssingToRow(job);
          })
          .catch((err) => {
            // An error occurred
          });
      }
    },
    refresh() {
      axios
        .post("/j/getinvoice", { unique: this.form.invoice.invoice_unique })
        .then((response) => {
          if (response.data.data) {
            this.form = response.data.data;
          }
        });
    },
    addInvoiceRow() {
      var errors = "";
      if (this.invoice_item_row.qty == 0 || this.invoice_item_row.qty == "") {
        errors += "<p>Please enter valid quantity</p>";
      }

      if (
        this.invoice_item_row.description == "" ||
        this.invoice_item_row.description == null
      ) {
        errors += "<p>Please enter a description</p>";
      }
      if (!(parseInt(this.invoice_item_row.amount) >= 0)) {
        errors += "<p>Please enter a valid amount</p>";
      }

      if (!(parseInt(this.invoice_item_row.paid) >= 0)) {
        errors += "<p>Please enter a valid paid amount</p>";
      }

      if (parseInt(this.invoice_item_row.paid) > parseInt(this.invoice_item_row.amount)) {
        errors += "<p>Please enter a valid paid amount</p>";
      }
      if (errors != "") {
        this.$notify({
          group: "error",
          type: "error",
          title: "Error",
          text: errors,
        });
      } else {
        this.form.invoice_item.push(this.invoice_item_row);

        this.form.total =
          parseInt(this.form.total) + parseInt(this.invoice_item_row.amount);
        this.form.paid = parseInt(this.form.paid) + parseInt(this.invoice_item_row.paid);
        this.form.balance =
          parseInt(this.form.balance) + parseInt(this.invoice_item_row.balance);

        this.invoice_item_row = {
          id: null,
          job_unique: null,
          qty: 0,
          description: null,
          amount: 0,
          paid: 0,
          balance: 0,
          status: "store",
        };
        this.disable_item_inser_row = false;
        if (this.form.invoice.id != null) {
          this.save();
        }
      }
    },
    openCustomerList() {
      this.$refs["customer-list-component"].openList();
    },
    addCustomer(newTag) {
      const tag = {
        name: newTag,
        id: null,
      };
      this.customers.push(tag);
      this.form.customer = tag;
    },
    getCustomers(query) {
      axios.post("/j/customers", { search: query }).then((response) => {
        if (response.data.data) {
          this.customers = response.data.data;
        }
      });
    },

    getInvoices(query) {
      axios.post("/j/invoices", { search: query }).then((response) => {
        if (response.data.data) {
          this.invoices = response.data.data;
        }
      });
    },
    save() {
      axios.post("/j/save/invoice", this.form).then((response) => {
        if (response.data.errors) {
          this.$notify({
            group: "error",
            type: "error",
            title: "Error",
            text: response.data.text,
          });
        } else {
          this.form = response.data.data;
          history.pushState(null, null, "/invoice/" + this.form.invoice.invoice_unique);
        }
      });
    },
    changeInvoice() {
      document.location = "/invoice/" + this.form.invoice.invoice_unique;
    },
    clearInvoice() {
      document.location = "/invoices";
    },
  },
  watch: {
     "form.total": function(){
        this.form.total = this.form.total.toFixed(2);
        }, 
         "form.balance": function(){
        this.form.balance = this.form.balance.toFixed(2);
        },
         "form.paid": function(){
        this.form.paid = this.form.paid.toFixed(2);
        }
  },
};
</script>
