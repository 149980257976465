<style scoped></style>
<template>
  <div>
    <b-modal hide-footer ref="jobs_modal" size="lg" title="Job list">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <b-form-input v-model="form.search" placeholder="Search"></b-form-input>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-table
                small
                striped
                hover
                :fields="fields"
                :items="items"
                responsive="sm"
                show-empty
                :empty-text="'There are no related jobs to show'"
              >
                <template #empty="scope">
                  <p class="text-center mt-3">{{ scope.emptyText }}</p>
                </template>
                <template #emptyfiltered="scope">
                  <p class="text-center mt-3">{{ scope.emptyFilteredText }}</p>
                </template>

                <template #cell(job_unique)="data">
                  {{ data.item.job_unique }}
                </template>

                <template #cell(customer)="data">
                  {{ data.item.customer }}
                </template>

                <template #cell(description)="data">
                  {{ data.item.description }}
                </template>

                <template #cell(amount)="data">
                  {{ data.item.amount }}
                </template>

                <template #cell(paid)="data">
                  {{ data.item.paid }}
                </template>

                <template #cell(balance)="data">
                  {{ data.item.balance }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell(id)="data">
                  <b-button
                    size="sm"
                    class="float-end"
                    variant="dark"
                    @click="selectItem(data.item)"
                    ><b-icon icon="plus" aria-hidden="true"></b-icon
                  ></b-button>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      form: {
        search: "",
        except: [],
        customer_id: null,
      },
      fields: [
        { key: "job_unique", label: "Job number" },
        { key: "customer", label: "Customer" },
        { key: "description", label: "Description" },
        { key: "amount", label: "Amount" },
        { key: "paid", label: "Paid amount" },
        { key: "balance", label: "Balance" },
        { key: "id", label: "" },
      ],
      items: [],
    };
  },
  computed: {},
  methods: {
    openList(availableItems, customer_id) {
      this.form.except = availableItems;
      this.form.customer_id = customer_id;
      this.$refs["jobs_modal"].show();
      this.getJobs();
    },
    getJobs() {
      axios.post("/j/jobs-to-invoice", this.form).then((response) => {
        if (response.data.data) {
          this.items = response.data.data;
        }
      });
    },
    selectItem(item) {
      this.$parent.addJobItemRow(item);
      this.$refs["jobs_modal"].hide();
    },
  },
  watch: {
    "form.search": function () {
      this.getJobs();
    },
  },
};
</script>
