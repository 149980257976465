import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Notifications from 'vue-notification'
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.debug = true; 
Vue.config.productionTip = false;
Vue.config.silent = false;
Vue.config.devtools = true;

Vue.use(VueSweetalert2)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Notifications)





Vue.mixin({
    data() {
        return { 
            
        }
    },
    mounted() { 
    },
    methods: {  
    },
    watch: {
        
    },
    computed: {

    },
    filters: {
   
    }
});

Vue.component('invoice-component', require('./components/Invoice.vue').default);
const app = new Vue({
    el: '#vue_invoice'
});
